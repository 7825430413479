import React, { useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Select from 'react-select';
import MainContent from '../components/MainContent';
import useGetUserRole from '../hooks/useGetUserRole';
import usePermissions from '../hooks/usePermissions';
import Spinner from '../components/spinner/Spinner';
import ClassSessionsTableView from '../components/ClassSessionsTableView';
import CreateClassSession from '../components/CreateClassSession';
import Modal from '../components/Modal';
import useFetchDatav2 from '../hooks/useFetchDatav2';
import ClassSessionCalendarSchedule from '../components/ClassSessionCalendarSchedule';

function ClassSessions() {
  const permissions = usePermissions();
  const role = useGetUserRole();
  const [modalOpen, setModalOpen] = useState(false);
  const [cohortId, setCohortId] = useState('');
  const [cohortName, setCohortName] = useState('');

  const [
    showClassSessionsCalendarSchedule,
    setShowClassSessionsCalendarSchedule,
  ] = useState(true);

  const toggleClassSessionsCalendarSchedule = () => {
    setShowClassSessionsCalendarSchedule(!showClassSessionsCalendarSchedule);
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const { data: cohortsData } = useFetchDatav2(
    ['cohorts'],
    '/cohorts',
    {},
    "Couldn't get cohorts. Please try again!",
    true
  );

  const cohortOptions = cohortsData
    ? cohortsData?.data?.cohorts.map((cohort) => ({
        value: cohort.id,
        label: `${cohort?.status || ''} ${cohort.name} - ${
          cohort.no_of_students || 0
        } students`,
      }))
    : [];

  const { data, isLoading } = useFetchDatav2(
    ['class-sessions', cohortId],
    '/class-sessions',
    {
      params: { cohortId },
    },
    "Couldn't get class sessions. Please try again!",
    !!cohortId
  );

  return (
    <>
      <MainContent full>
        <div className="bg-white px-2 py-5 rounded-lg flex justify-between">
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/"
              className="flex items-center"
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Home</p>
            </NavLink>
          </Breadcrumbs>
          <div className=" flex space-x-2">
            {(permissions?.includes('CreateClassSession') ||
              role === 'SuperAdmin') && (
              <button
                type="button"
                className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer"
                onClick={toggleModal}
              >
                Create Class Session
              </button>
            )}
          </div>
        </div>
        <div className="flex justify-between items-center align-middle mt-4 bg-white p-2 gap-4 rounded-lg">
          <div className="flex flex-col space-y-1 w-[20rem]">
            <label htmlFor="cohort" className="text-base">
              Select Cohort
            </label>
            <Select
              placeholder="Select a cohort"
              isClearable
              className="react-dropdown"
              classNamePrefix="dropdown"
              options={cohortOptions}
              onChange={(event) => {
                setCohortId(event?.value);
                setCohortName(event?.label);
              }}
            />
          </div>
          <div className="space-x-2">
            {(permissions?.includes('CreateGroupAssignment') ||
              role === 'SuperAdmin') && (
              <button
                type="button"
                className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer"
                onClick={toggleClassSessionsCalendarSchedule}
              >
                {showClassSessionsCalendarSchedule
                  ? 'Display Class Sessions in Table View'
                  : 'Display Class Sessions in Calendar View'}
              </button>
            )}
          </div>
        </div>
        {isLoading && cohortId && (
          <div className="mt-5 bg-white py-5">
            <Spinner />
          </div>
        )}
        {!isLoading && cohortId && showClassSessionsCalendarSchedule && (
          <ClassSessionCalendarSchedule
            classSessions={data?.data?.class_sessions?.map((event) => ({
              ...event,
              startDate: new Date(event.startDate),
              endDate: new Date(event.endDate),
            }))}
            role={role}
            permissions={permissions}
          />
        )}
        {!isLoading && !showClassSessionsCalendarSchedule && (
          <ClassSessionsTableView
            classSessions={data?.data?.class_sessions || []}
            tableTitle={`List of ${cohortName} Class Sessions`}
          />
        )}
      </MainContent>
      <Modal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        title="Create Class Session"
      >
        <CreateClassSession toggleModal={toggleModal} />
      </Modal>
    </>
  );
}

export default ClassSessions;
